const colors = [
    {
        level: 1,
        colorCode: '#84DEDE',
    },
    {
        level: 2,
        colorCode: '#37BAE9',
    },
    {
        level: 3,
        colorCode: '#7A5FBA',
    },
    {
        level: 4,
        colorCode: '#FED747',
    },
    {
        level: 5,
        colorCode: '#ACE0B8',
    },
    {
        level: 6,
        colorCode: '#0739A6',
    },
    {
        level: 7,
        colorCode: '#ADBA5F',
    },
    {
        level: 8,
        colorCode: '#CF9B98',
    },
    {
        level: 9,
        colorCode: '#ECBCDC',
    },
    {
        level: 10,
        colorCode: '#D7B49B',
    },
];

export const levelColor = (level) => {
    return colors
        .filter((a) => {
            return level === a.level;
        })
        .map((color, index) => {
            const colors = color.colorCode;
            return colors;
        });
};
export default levelColor;
