import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
export default async function SwitchNetwork(chainId, requiredBlockChain) {
    return new Promise(async (resolve, reject) => {
        const cookies = new Cookies();
        const cookieValues = cookies.get('tokens');
        let walletType = '';
        if (cookieValues) {
            walletType = cookieValues.walletType;
        }
        if (walletType) {
            chainId = parseInt(chainId);
            // Supported Chain ID
            const ethereumChainID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID);
            const polygonChainID = parseInt(
                process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID
            );
            // Current Blockchain
            const currentBlockChain =
                chainId === ethereumChainID ? 'ethereum' : 'polygon';
            const requiredChainID =
                requiredBlockChain === 'ethereum'
                    ? ethereumChainID
                    : polygonChainID;
            // Current Network
            const currentNetwork =
                currentBlockChain === 'ethereum' ? 'Ethereum' : 'Polygon';
            const otherNetwork =
                currentBlockChain === 'ethereum' ? 'Polygon' : 'Ethereum';
            // Switch Blockchain Network Chain ID In Hex Format
            const convertNumberToHex = requiredChainID.toString(16);
            const networkChainID = `0x${convertNumberToHex}`;
            if (requiredChainID !== chainId) {
                await Swal.fire({
                    title: 'Switch Blockchain Confirmation',
                    text: `Your current metamask network is ${currentNetwork} Blockchain. In order to connect metamask on ${otherNetwork} Blockchain you need to switch your network. Please confirm to proceed.`,
                    icon: 'warning',
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonColor: '#7557BB',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const { ethereum } = window;
                        let params = [{ chainId: networkChainID }];
                        ethereum
                            .request({
                                method: 'wallet_switchEthereumChain',
                                params,
                            })
                            .then((result) => {
                                resolve(true);
                            })
                            .catch((error) => {
                                Swal.fire(
                                    'Switch Cancelled!',
                                    `You have cancelled request for switch to ${otherNetwork} Blockchain Network`,
                                    'info'
                                );
                                resolve(false);
                            });
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(true);
            }
        } else {
            resolve(true);
        }
    });
}
