import React from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import contract from '../../Configs/Contract';
import blockChainData from '../../Hooks/blockChainData';
const SearchListCard = (props) => {
    const getContractAddress = (currency) => {
        let chainID =
            currency === 'MATIC'
                ? process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID
                : process.env.NEXT_PUBLIC_CHAIN_ID;
        let getBlockChainDataValue = blockChainData(chainID);
        return getBlockChainDataValue.contractAddress;
    };
    return (
        <div className={`${styles.searchListCardBox}`}>
            <div className={`${styles.titlebox}`}>
                <span>{props.title}</span>
            </div>
            {props.list?.length > 0 ? (
                props.list.slice(0, 4).map((data, index) => {
                    return (
                        <div key={index}>
                            <div className={`${styles.cardBox}`}>
                                <div className={`${styles.box}`}>
                                    <Link
                                        href={
                                            props.type === 'useres'
                                                ? `/profile/${data?.public_address}`
                                                : `/token/${contract[getContractAddress(data?.currency)]}:${data?.token_id}`
                                        }
                                    >
                                        <a target="_blank">
                                            <div
                                                className={`${styles.imageBox}`}
                                            >
                                                <img
                                                    style={{
                                                        width: '40px',
                                                        height: '40px',
                                                    }}
                                                    src={
                                                        props.type === 'useres'
                                                            ? `${
                                                                  data?.profile_photo_thumb ??
                                                                  '/assets/img/avatars/user_avatar5.jpg'
                                                              }`
                                                            : `${
                                                                  data?.image
                                                                      ?.home_page_listings ??
                                                                  '/assets/img/avatars/user_avatar5.jpg'
                                                              }`
                                                    }
                                                    alt={data.title}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        let arr = (
                                                            props.type ===
                                                            'useres'
                                                                ? `${
                                                                      data?.profile_photo_thumb ??
                                                                      '/assets/img/avatars/user_avatar5.jpg'
                                                                  }`
                                                                : `${
                                                                      data
                                                                          ?.image
                                                                          ?.home_page_listings ??
                                                                      '/assets/img/avatars/user_avatar5.jpg'
                                                                  }`
                                                        )?.split('.');
                                                        arr.pop();
                                                        e.target.src =
                                                            arr.join('.') +
                                                            '.jpg';
                                                    }}
                                                />
                                            </div>
                                        </a>
                                    </Link>

                                    <div className={`${styles.details}`}>
                                        <Link
                                            href={
                                                props.type === 'useres'
                                                    ? `/profile/${data?.public_address}`
                                                    : `/token/${contract[getContractAddress(data?.currency)]}:${data?.token_id}`
                                            }
                                        >
                                            <a target="_blank">
                                                <div
                                                    className={`${styles.productTitle}`}
                                                >
                                                    {props.type === 'useres'
                                                        ? data.name
                                                        : data.title}
                                                </div>
                                            </a>
                                        </Link>
                                        <Link
                                            href={
                                                props.type === 'useres'
                                                    ? `/profile/${data?.public_address}`
                                                    : `/profile/${data?.created_by?.public_address}`
                                            }
                                        >
                                            <a target="_blank">
                                                <div
                                                    className={`${styles.subTitle}`}
                                                >
                                                    {props.type ===
                                                    'collections'
                                                        ? `by ${
                                                              data?.created_by
                                                                  ?.username
                                                                  ? data
                                                                        ?.created_by
                                                                        ?.username
                                                                  : data?.created_by?.public_address?.substring(
                                                                        0,
                                                                        6
                                                                    ) +
                                                                    '...' +
                                                                    data?.created_by?.public_address?.substr(
                                                                        data
                                                                            ?.created_by
                                                                            ?.public_address
                                                                            ?.length -
                                                                            4
                                                                    )
                                                          }`
                                                        : props.type ===
                                                            'useres'
                                                          ? `${data.follower_cnt ?? 0} followers`
                                                          : props.type ===
                                                              'items'
                                                            ? `by ${
                                                                  data
                                                                      ?.created_by
                                                                      ?.username
                                                                      ? data
                                                                            ?.created_by
                                                                            ?.username
                                                                      : data?.created_by?.public_address?.substring(
                                                                            0,
                                                                            6
                                                                        ) +
                                                                        '...' +
                                                                        data?.created_by?.public_address?.substr(
                                                                            data
                                                                                ?.created_by
                                                                                ?.public_address
                                                                                ?.length -
                                                                                4
                                                                        )
                                                              }`
                                                            : ''}
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <p className={`${styles.nodataavalabile}`}>
                    No search result found
                </p>
            )}
        </div>
    );
};

export default SearchListCard;
